body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a:hover {
  color: rgba(0, 0, 0, 0.87) !important;
}

.MuiDrawer-docked {
  z-index: 10;
}

/* -- Login -- */
.login-back {
  background-image: url("IMG_3581_testBD.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
}

.login-container {
  text-align: center;
}

.BP_logo {
  margin-top: 3%;
  margin-bottom: 1%;
}

.login-form {
  text-align: center;
}

.login-row {
  margin-top: 15%;
}

.login-action {
  display: block !important;
}

.forgot-pwd {
  font-size: 12px !important;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.pwd-btn {
  float: right;
}

/* -- End Login -- */

/* -- Home -- */

.card-height {
  height: 100%;
}

.ant-upload-select-text {
  border: gray 2px dashed;
  text-align: center;
  width: 100%;
}

.ant-upload-text {
  margin-top: 6% !important;
  margin-bottom: 6% !important;
}

/* -- End Home -- */

/* -- Tender Offers -- */

.td[class^="column-"] span {
  white-space: pre-line;
}

.alloffers thead {
  background-color: #cdd1ec;
}

.alloffers th {
  font-weight: bold;
}

.alloffers {
  width: auto;
  table-layout: auto;
  /*margin-top: 2% !important;*/
}

.instruction {
  padding: 2%;
  text-align: center;
  font-size: 16px !important;
  font-weight: bold !important;
  color: #e50b0b;
}

.add-col-btn {
  margin-right: 2%;
}

.actions-btn {
  position: absolute !important;
  top: 2em;
  padding: 0 !important;
  left: 31em;
  margin-left: 1%;
}

.actions-btn-menu {
  position: fixed !important;
  top: 20em;
  left: 0;
  z-index: 1000;
}

.MuiDrawer-paperAnchorRight {
  width: 60% !important;
}

/*.list-page > div.MuiToolbar-root {
  width: 80% !important;
}*/

.tender-title {
  font-weight: 100;
  font-size: 16px;
}

.dot {
  height: 5px;
  width: 5px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 1%;
  margin-left: 1%;
}

.offerCell {
  min-width: 300px;
}

.MuiDrawer-paperAnchorDockedLeft {
  z-index: auto;
}

/* Sticky table header  */
.listoffers [class*="RaList-main"] .MuiPaper-root {
  overflow-y: auto;
  height: 600px;
}
.alloffers thead th {
  position: sticky;
  top: 0;
}
.alloffers table {
  border-collapse: collapse;
  width: 100%;
}
.alloffers th,
td {
  padding: 8px 16px;
}
.alloffers th {
  background: #cdd1ec;
}

/* -- End Tender Offers -- */

/* -- Filters -- */

.issue {
  display: block;
  margin-left: 2%;
}

/*.info-peFunds{
  position: absolute !important;
  bottom: 28%;
  left: 24%;
}*/
.filter-container {
  width: 100% !important;
}

.filter-field .fullsearch span {
  font-size: 16px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: #e50b0b;
}

.advanced-search-p {
  font-weight: bold !important;
  color: #e50b0b;
}

.adv-filter-icon {
  color: #e50b0b;
  margin-right: 4px;
}

.subtitle {
  text-align: center;
  background-color: #5e6ec7;
  margin-bottom: 5%;
  font-weight: bold;
  color: white;
  padding-bottom: 3%;
  padding-top: 3%;
}

.big-subtitle {
  text-align: center;
  background-color: #cdd1ec;
  margin-bottom: 20px;
  font-weight: bold;
  color: #3f51b5;
  padding-bottom: 7px;
  padding-top: 7px;
}

.subtitle2 {
  text-align: center;
  margin-bottom: 5%;
  padding-bottom: 3%;
  padding-top: 3%;
  /* font-style: italic; */
  text-transform: uppercase;
  font-weight: bold;
  border: 1px solid black;
  font-size: 14px;
}

.filters {
  width: 50%;
}

/*.filters-panel {
  width: 100%;
}*/

.advanced-search {
  width: 100%;
}

.filters-grid {
  margin: 0px !important;
  /*width: 100% !important;
  min-width: 100% !important;*/
}

.year div.filter-field {
  width: 100% !important;
}

.filter-field {
  width: 100%;
}

.filter-field label {
  font-size: 14px !important;
  display: block;
  padding-right: 10px;
  padding-left: 33px;
  text-indent: -33px;
}

.filter-field label span[class^="MuiCheckbox"] {
  vertical-align: middle;
}

.filter-field label span[class^="MuiTypography"] {
  font-size: 14px !important;
  vertical-align: middle;
}

.filter-field legend {
  font-size: 22px;
  color: #3f51b5;
  font-style: italic;
  font-weight: bold;
  line-height: normal;
  /* border-bottom: 1px solid darkgray;
  padding-bottom: 4%; */
  width: 100%;
}

.filter-tab button {
  font-size: 14px !important;
  font-weight: bold;
  opacity: 1 !important;
}

.filter-tab {
  background-color: #3f51b5;
  color: white;
}

.MuiTabs-indicator {
  height: 5px !important;
  background-color: #e50b0b !important;
}

.checkbox-col div {
  flex-direction: column !important;
}

[class*="makeStyles-actions"] .filters-panel {
  display: none !important;
}

.filters-panel .MuiCheckbox-root {
  height: auto !important;
  padding: 4px !important;
}

.top-filter {
  width: 100%;
  padding-left: 3%;
}

.threshold {
  border: 1px solid darkgrey;
  border-radius: 8px;
  margin-top: 2%;
  width: 90%;
}

.threshold form label + .MuiInput-formControl {
  margin-top: 0px !important;
}

.threshold-title {
  font-weight: bold;
  font-style: italic;
  margin-top: 2%;
  margin-left: 2%;
  color: #3f51b5;
  width: 100%;
}

.width-title {
  width: 100%;
  z-index: 1000;
}

.legend {
  margin: 2% !important;
}

.title-legend {
  cursor: pointer;
  margin-left: 5%;
  color: #3f51b5 !important;
}

.title-legend:hover {
  color: darkgray;
}

.MuiExpansionPanelSummary-expandIcon span {
  color: #e50b0b;
}

.checkbox-col.col-name legend span {
  white-space: pre-line;
}

.ant-tabs-content {
  padding: 24px;
}

.ant-tabs-bar {
  margin: 0px !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.ant-tabs-nav-container {
  background-color: #3f51b5;
  min-height: 60px !important;
}

.ant-tabs-tab {
  min-height: 60px !important;
  padding: 16px 12px !important;
}

.ant-tabs-nav {
  min-height: 60px !important;
  color: white;
  font-weight: bold;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.ant-tabs-nav .ant-tabs-tab:hover {
  color: white !important;
  font-weight: bold;
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: white !important;
  font-weight: bold !important;
}

.ant-tabs-tab-arrow-show {
  color: white !important;
  width: 40px !important;
}

.ant-tabs-ink-bar {
  background-color: #e50b0b !important;
  height: 5px !important;
  position: relative !important;
}

/* -- End Filter -- */

/* -- Select Col -- */

.col-list {
  padding-left: 0 !important;
}

.col-select {
  display: block !important;
}

.col-elt {
  color: white;
  background: #cdd1ec;
  text-align: center;
  padding-top: 7px;
  padding-bottom: 7px;
  font-weight: bold;
  color: #5e6ec7;
}

.col-family:hover {
  background-color: #3f51b5 !important;
}

.col-family:hover p {
  color: white;
}

.col-family.Mui-expanded {
  background-color: #3f51b5 !important;
}

.col-family.Mui-expanded p {
  color: white;
}

.col-family p {
  font-weight: bold;
  font-size: 14px !important;
  color: black;
}

.col-name {
  white-space: pre-line;
}

.info-bidder-intention {
  right: 30%;
}

/* -- End Select Col -- */

/* -- Show Offer -- */

.tabs-offer .MuiTabs-root {
  background-color: #3f51b5 !important;
}

.tabs-offer .MuiTabs-root .MuiTabs-scrollable span {
  font-weight: bold;
  font-size: 14px !important;
  color: white;
}

.MuiTab-textColorInherit {
  opacity: 1 !important;
}

.tabs-offer .MuiTabs-scrollButtons {
  color: white;
}

.record-title {
  margin-bottom: 0px !important;
  color: darkblue;
  font-weight: bold;
}

.offer-grid {
  margin-top: 2%;
}

.offer-grid p {
  white-space: pre-line;
}

.link {
  color: #e50b0b;
}

.link-p {
  margin-top: 1%;
}

.link svg {
  vertical-align: middle;
}

.link:hover {
  color: #3f51b5 !important;
}
/* -- End Show Offer -- */

/* -- Statistics -- */
.stats thead {
  background-color: #f5f5f5;
}

.recap-grid {
  width: 100%;
  margin-bottom: 2%;
  margin-top: 1%;
}

.rdt_TableCol_Sortable {
  font-weight: 600;
  font-size: 14px;
  color: #000000de;
  font-family: "Roboto", "Helvetica";
}

.rdt_TableCell {
  font-size: 14px;
  color: #000000de;
  font-family: "Roboto", "Helvetica";
}

.rdt_TableHeadRow {
  background-color: #f5f5f5 !important;
}

/* -- End Statistics - */

/* -- Other -- */

.timeout .ant-modal-close-x {
  display: none;
}

.MuiSnackbarContent-root {
  display: none !important;
}

div[class^="MuiSnackbarContent-message"] {
  display: none !important;
}

div[class^="Connect-Notification--warning-"] {
  background: transparent !important;
}

/*.MuiButton-startIcon{
  margin: 0px !important; 
}*/

/* -- End Other -- */

/* -- Users Offer -- */

.users thead {
  background-color: #f5f5f5;
}

/* -- End Users Offer -- */

.col-selection {
  height: 100%;
  width: 200px;
  transition: margin 0.5s;
  margin: 1em -250px 1em 1em;
}

.col-selection.is-nav-open {
  margin-right: 0;
}

.col-name {
  padding: 0px !important;
}

.checkbox {
  display: block;
}

.clear-filters {
  left: 224px;
  top: -37px;
}

div[class^="CardActions-cardActions"] .filters-panel {
  display: none;
}

.drop {
  text-align: center;
  border: 1px dotted grey;
  padding: 10%;
}

.drop:hover {
  cursor: pointer;
}

.card-actions {
  text-align: center;
  display: block !important;
}

/*[class^="MuiAppBar-colorSecondary"]{
  background-color: #3f51b5 !important;
}*/

.MuiAppBar-colorSecondary-40 {
  background-color: #3f51b5 !important;
}

.send-btn {
  border-color: #2196f3 !important;
  color: #2196f3 !important;
}

.space-up {
  margin-top: 1%;
}

/*button[aria-label^="Export"] {
  top: 16%;
  position: fixed;
  right: 25px;
}*/

.col-checkbox {
  display: inline-block;
}

.span-checkbox {
  width: 85%;
  display: inline-block;
}

.select-referent {
  margin-bottom: 10px;
}

footer {
  background-color: transparent;
}

.footer-adv {
  float: right;
}

.login-btn {
  width: 100px;
  margin-top: 2% !important;
}

.heart {
  color: #e25555;
}

.BP_view {
  width: 100%;
}

td span {
  font-size: 14px !important;
}

.filter-field label {
  position: relative;
}

.info {
  position: relative;
  float: right;
}

iframe {
  display: none;
}

.MuiDrawer-docked {
  z-index: 0 !important;
}

.MuiDrawer-docked div[class^="RaSidebar-fixed"] {
  position: relative;
}
